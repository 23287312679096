$min-contrast-ratio: 2.0;

//$abody-bg:    #011638;
//$body-bg: #508bfc;
$body-bg: #151a30;
$body-color: #E5FFDE;
//$body-color: #FFFFFF;


$bg-dark: #222b45;

$bg-primary: #222b45;



//$primary: #46B1C9;
//$primary: #88BBBB;
$primary: #222b45;

$secondary: #E3B505;
$success: #E26628;
$info: #E5FFDE;
//$warning:
//$danger:
//$light:
//$dark:

//$primary:       $blue !default;
//$secondary:     $gray-600 !default;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-900 !default;


@import "../node_modules/bootstrap/scss/bootstrap";


//disable anoying waringn in tiny mce
.tox-notification {display: none !important;}
